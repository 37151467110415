import React, { useState, useEffect } from "react";
import axios from "axios";

const Banner: React.FC = () => {
  const [videoUrls, setVideoUrls] = useState<string[]>([]); // Array to hold video URLs
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0); // Track the current video index

  useEffect(() => {
    fetchVideoUrls();
  }, []);

  const fetchVideoUrls = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_HTTP_BACKEND_URL}api/messages/banner`
      ); // Replace with your API endpoint

      // Shuffle the response.data (array of URLs) using Fisher-Yates algorithm
      const shuffledUrls = shuffleArray(response.data);

      setVideoUrls(shuffledUrls); // Set the shuffled array
    } catch (error) {
      console.error("Error fetching video URLs:", error);
    }
  };

  // Fisher-Yates (Knuth) Shuffle function
  const shuffleArray = (array: string[]) => {
    let shuffledArray = [...array]; // Create a copy to avoid mutating the original array
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ]; // Swap elements
    }
    return shuffledArray;
  };

  // Advance to the next video when the current one ends
  const handleVideoEnded = (e: any) => {
    return e.target?.setAttribute("style", "display: none;");
    setCurrentVideoIndex((prevIndex) => {
      if (prevIndex + 1 < videoUrls.length) {
        return prevIndex + 1;
      } else {
        fetchVideoUrls();
        return 0;
      }
    }); // Loop back to the first video if at the end
  };

  return (
    <div className="relative flex flex-col w-screen h-[100dvh] bg-[#002051] banner-page">
      <div className="relative flex flex-col w-screen h-[100dvh]">
        {videoUrls.length > 0 ? (
          <video
            key={currentVideoIndex}
            className="absolute top-0 left-0 w-full h-full object-contain"
            src={videoUrls[currentVideoIndex]} // Play the current video
            autoPlay
            loop={false} // Disable looping to allow switching videos
            muted
            playsInline // Ensures videos play inline on mobile devices (avoids fullscreen)
            preload="auto" // Preload video for better user experience
            controlsList="nodownload" // Prevent video download in most browsers
            disablePictureInPicture // Disable Picture-in-Picture mode
            onEnded={handleVideoEnded}
            aria-label="Video playback" // Accessibility: Provide a label describing the video
          >
            Your browser does not support the video tag. Please update your
            browser.
          </video>
        ) : (
          <div className="relative z-10 flex items-center justify-center h-full">
            <p className="text-white text-xl">Loading videos...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
