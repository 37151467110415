import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useBlockBackButton = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const blockBackNavigation = () => {
      // Push a new state to prevent back navigation
      window.history.pushState(null, "", window.location.href);
    };

    const handlePopState = (event) => {
      event.preventDefault();
      blockBackNavigation();
      navigate(window.location.pathname); // Force navigation to the current page
    };

    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = ""; // Triggers browser confirmation dialog
    };

    // Push initial dummy state to browser history
    blockBackNavigation();

    // Add event listeners
    window.addEventListener("popstate", handlePopState);
    window.addEventListener("beforeunload", handleBeforeUnload);

    // On reload, push a new state to reset history
    window.onload = blockBackNavigation;

    return () => {
      // Cleanup event listeners
      window.removeEventListener("popstate", handlePopState);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);
};

export default useBlockBackButton;
