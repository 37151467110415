import React, { useEffect, useCallback, useState } from "react";
import { handleGTM } from "../utils/helper";
import useBlockBackButton from "../utils/preventBrowserBack";

const ThankYou: React.FC = () => {
  useBlockBackButton();
  const [searchParams, setSearchParams] = useState<string>("");

  // Safely fetch search params
  useEffect(() => {
    if (typeof window !== "undefined") {
      setSearchParams(window.location.search || "");
    }
    handleGTM("NOTIFY_THANK_YOU_MESSAGE");
  }, []);

  // Memoized function to handle Learn More click
  const handleLearnMore = useCallback(() => {
    if (searchParams.includes("mall")) {
      handleGTM("ENGAGE_MALL_TAP_CTA_LEARN_MORE");
    } else if (searchParams.includes("ads")) {
      handleGTM("ENGAGE_MICRO_TAP_CTA_LEARN_MORE");
    }
  }, [searchParams]);

  return (
    <a
      className="thank-you-orientation bg-[#002051] relative mobile-form flex flex-col w-screen h-[100dvh] overflow-hidden"
      onClick={handleLearnMore}
      href="https://servedby.flashtalking.com/click/7/262493;9093773;50126;211;0/?ft_width=1&ft_height=1&gdpr=&gdpr_consent=&us_privacy=&url=40704861"
      target="_blank"
      rel="noopener noreferrer"
    >
      {/* Logo */}
      <img
        src="logo.png"
        alt="Lady's Choice Logo"
        className="absolute top-3 left-6 z-10 w-[92px]"
        width={136}
        height={173}
        onError={(e) => (e.currentTarget.style.display = "none")}
      />

      {/* Thank You Section */}
      <div className="thank-you-wrapper w-full h-[36dvh] relative z-[1] flex flex-col items-center justify-end">
        <img
          src="image/ThankYou.png"
          alt="Thank You Banner"
          className="w-3/4"
          width={443}
          height={199}
          onError={(e) => (e.currentTarget.style.display = "none")}
        />
        {/* Anchor with GTM */}
        <a
          onClick={handleLearnMore}
          href="https://www.ladyschoice.com.ph/christmas-recipes.html?utm_source=thetradedesk&utm_medium=display&utm_content=_&utm_campaign=Arya%20%28Lady%27s%20Choice%29_CN003532_CF1188_BF0469_PH&utm_term=Broad2554"
          target="_blank"
          rel="noopener noreferrer"
          className="px-12 translate-y-5 py-[9px] rounded-3xl text-white bg-[#f31c28] hover:bg-[#e61923] transition-colors"
        >
          LEARN MORE
        </a>
      </div>

      {/* Video Section */}
      <div className="thank-you-video-wrapper relative top-shadow bg-image w-full h-[64dvh]">
        <img
          src="image/thankYouBgLandscape.png"
          alt="thank you bg"
          className="landscape-thanks-image hidden w-full h-full object-cover object-left scale-[1.25] translate-x-10 translate-y-2"
        />

        <img
          src="image/thankYouBgPortrait.png"
          alt="thank you bg"
          className="portrait-thanks-image block w-full h-full object-cover object-center "
        />

        <img
          src="image/productsForm.png"
          alt="Lady's Choice Products"
          className="portrait-thanks-image block absolute -bottom-2 left-1/2 -translate-x-1/2 z-10 max-w-[340px] w-full"
          width={497}
          height={234}
          onError={(e) => (e.currentTarget.style.display = "none")}
        />
      </div>
    </a>
  );
};

export default ThankYou;
