import React, { useCallback, useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { connectSocketIO } from "../utils/socket";
import { handleGTM } from "../utils/helper";
import useResponsiveScale from "../utils/dynamicScaling";

const QRPage: React.FC = () => {
  const { scaleValue, topValue } = useResponsiveScale();
  const [screenId, setScreenId] = useState<string | null>(null);
  const [familyNamesQueue, setFamilyNamesQueue] = useState<string[]>([]);
  const [currentFamilyName, setCurrentFamilyName] = useState<string | null>(
    null
  );
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const socketRef = useRef<any>(null);

  // Handle 'screenId' event
  const handleScreenIdEvent = useCallback((data: any) => {
    if (data?.screenId) {
      setScreenId(data.screenId);
    } else {
      console.error("No screenId in event data");
    }
  }, []);

  // Handle 'updateFamilyName' event - manage a queue of family names
  const handleFamilyNameEvent = useCallback((data: any) => {
    if (data?.familyName) {
      setFamilyNamesQueue((prevQueue) => {
        const newQueue = [...prevQueue, data.familyName];

        return newQueue;
      });
    } else {
      console.error("No familyName in event data");
    }
  }, []);

  useEffect(() => {
    // Establish WebSocket connection

    socketRef.current = connectSocketIO();
    const socket = socketRef.current;

    // Listen for events
    socket.on("screenId", handleScreenIdEvent);
    socket.on("updateFamilyName", handleFamilyNameEvent);

    // Cleanup on unmount
    return () => {
      socket.off("screenId", handleScreenIdEvent);
      socket.off("updateFamilyName", handleFamilyNameEvent);
      socket.disconnect();
    };
  }, [handleScreenIdEvent, handleFamilyNameEvent]);

  useEffect(() => {
    // Function to display family names one by one with delay
    const displayFamilyNames = async () => {
      if (familyNamesQueue.length > 0) {
        handleGTM("ENGAGE_LC_SCAN_QR");

        for (let i = 0; i < familyNamesQueue.length; i++) {
          setCurrentFamilyName(familyNamesQueue[i]);
          setShowVideo(true);
          // Wait for a few seconds before showing the next family name
          await new Promise((resolve) => setTimeout(resolve, 15000)); // 15 seconds delay
        }

        // Once all family names have been shown, clear the queue

        setFamilyNamesQueue([]);
        setShowVideo(false); // Hide the video when finished
      }
    };

    if (familyNamesQueue.length > 0) {
      displayFamilyNames();
    }
  }, [familyNamesQueue]);

  // Generate the QR code URL with or without the screenId
  const adsParams = "utm_source=Inmall&utm_medium=QR&utm_campaign=LC";
  const qrCodeValue = screenId
    ? `${window.location.origin}/form?screenId=${screenId}&${adsParams}`
    : `${window.location.origin}/form?${adsParams}`;

  const dynamicStyle = {
    transform: `translate(-50%, -${topValue}%) scale(${scaleValue / 100})`,
  };

  //color code - #0a2359

  return (
    <div
      className={`main-wrapper qr-page ${
        !showVideo ? "bg-[#0a2359]" : "bg-[#002051]"
      } h-[1920px] w-[1080px]`}
      style={dynamicStyle}
    >
      {!showVideo ? (
        <div className="main-sub-wrapper relative bg-[#0a2359] h-full flex flex-col justify-between">
          <div className="before-qr-wrapper h-1/2 relative z-50">
            {/* QR Code and Static Content */}
            <div className="flex justify-center w-full">
              <p className="text-white font-manrope text-center max-w-[655px] w-full mt-[6rem] text-[40px] tracking-[0.01px]">
                SCAN QR TO MAKE A CUSTOM CHRISTMAS GREETING WITH
                <br />
                <span className="font-bold">JOSE MARI CHAN!</span>
              </p>
            </div>

            <div className="flex justify-center mt-[3rem]">
              <QRCode
                className="border-[6px] border-white size-[320px]"
                value={qrCodeValue}
              />
            </div>
            <div className="max-w-[681px] w-full mx-auto relative">
              <div className="init-name-spark w-full absolute -top-[32px] h-[220px] overflow-hidden " />
              <div
                className="mt-[3rem] animate-grow overflow-hidden"
                style={{ maxWidth: "681px" }}
              >
                <div className="w-[681px] h-full overflow-hidden">
                  <img
                    src="image/SendSpecialChristmasWishes.png"
                    alt="special wishes"
                    width={681}
                    height={179}
                    style={{ height: "179px", width: "681px" }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="before-video-wrapper flex justify-center relative h-[1000px]">
            <div className="">
              <div className="w-full h-full">
                <video
                  src="/video/LC_ Jose_Mari Chan_Video_01.mp4" // Ensure the correct path
                  className="before-video w-full h-full object-contain"
                  loop
                  autoPlay
                  muted
                  playsInline
                  preload="auto"
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <img
              src="image/meyoJar.png"
              alt="lady's choice meyo"
              className="hidden before-meyo-jar absolute bottom-[200px] right-[200px] mr-20 z-10 w-[152px]"
              width={152}
              height={193}
            />
            <img
              src="logo.png"
              alt="lady's choice logo"
              className="hidden before-logo absolute bottom-[50px] right-[50px] z-10 w-[404px] h-[184px]"
              width={404}
              height={184}
            />
          </div>
        </div>
      ) : (
        <div className="relative h-[1920px] flex flex-col after-main-wrapper">
          <div className="h-1/2 relative after-video-wrapper">
            <img
              src="logo.png"
              alt="lady's choice logo"
              className="absolute top-[120px] left-[50px] z-10 w-[218px]"
              width={278}
              height={128.65}
            />
            <div className="relative flex justify-center h-full qr-bg-image after-video-wrapper">
              <video
                src="/video/LC_Greting_DOOH_Video.mp4" // Ensure the correct path
                className="w-full object-cover bg-[#0a2359]"
                controls={false}
                autoPlay
                muted
                loop
                playsInline
                preload="auto"
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div className="after-qr-wrapper h-1/2 bg-[#002051] p-3">
            <div className="relative -translate-y-[36%] after-qr-nameFrame-wrapper">
              <div className="max-w-full mx-auto relative w-fit">
                <img
                  src="image/greetFrame.png"
                  className=""
                  alt="greet"
                  width={667.5}
                  height={782.5}
                />
                <div className="name-spark w-full absolute h-[364px] top-[310px] overflow-hidden" />
                <span
                  className={`max-w-[450px] w-full font-fondy text-white leading-[130%] text-center capitalize absolute left-1/2 -translate-x-1/2 top-[410px] family-name-box break-words
                    ${
                      currentFamilyName
                        ? currentFamilyName.length < 8
                          ? "text-[80px]"
                          : currentFamilyName.length <= 16
                          ? "text-[56px]"
                          : "text-[38px]"
                        : "text-[80px]"
                    }
                    `}
                  style={
                    {
                      "--family-fontSize": currentFamilyName
                        ? currentFamilyName.length < 8
                          ? "60px"
                          : currentFamilyName.length <= 16
                          ? "48px"
                          : "30px"
                        : "60px", // Default value if currentFamilyName is falsy
                    } as React.CSSProperties
                  }
                >
                  {currentFamilyName}
                  <br />
                  Family
                </span>
              </div>
            </div>
            <div className="after-qr-content-wrapper flex flex-row-reverse items-end justify-between pt-[10px] absolute bottom-[150px] left-1/2 -translate-x-1/2 max-w-[900px] w-full">
              <div className="flex flex-col">
                <p className="text-white font-avenirltpromedium text-center mt-4 text-[28px]">
                  SCAN QR TO
                </p>
                <div className="flex justify-center my-4">
                  <QRCode
                    className="border-[12px] border-white size-[220px]"
                    value={qrCodeValue}
                  />
                </div>
              </div>
              <div className="max-w-[1192px] w-full relative overflow-hidden">
                <div className="init-name-spark-2 w-full absolute top-3 h-[220px]" />
                <div
                  className="mt-[45px] animate-grow overflow-hidden"
                  style={{ maxWidth: "596px" }}
                >
                  <div className="w-[596px] h-full overflow-hidden">
                    <img
                      src="image/SendSpecialChristmasWishes.png"
                      alt="special wishes"
                      width={596}
                      height={177}
                      style={{ height: "177px", width: "596px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QRPage;
